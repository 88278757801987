.backButton {
	outline: none;
	border: none;
	padding: 0 1.5rem 4.25px 1.5rem;
	display: flex;
	align-items: center;
	background: transparent;
	width: 100%;
	color: #484851;
	font-size: 12px;
	gap: 10px;
}

.backButtonCompact {
	compose: backButton;
	outline: none;
	border: none;
	display: flex;
	width: 100%;
	align-items: center;
	background: transparent;
	padding: 0 0 21.5px 0;
	justify-content: center;
}
